@import '../../vars.scss';

.container {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.mapContainer {
  width: 100%;
  //height: 5rem;
  aspect-ratio: 2.3;
  border-radius: 1rem;
  overflow: hidden;
}

.infosContainer {
  margin-top: 1rem;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
}

.locationIcon {
  height: 1.2rem;
}

.text {
  display: flex;
  flex-direction: column;
  margin-left: 1rem;
}

.address {
  font-size: .9rem;
  color: $text-grey;
  margin-bottom: 0.3rem;
  font-weight: 600;
}
.address:last-child {
  margin-bottom: 0;
}
