@import '../../../vars';

.container {
  position: relative;
  border-radius: 0.4rem;
  overflow: hidden;
  width: 4rem;
  height: 4rem;
  box-shadow: 0 2px 5px 1px rgba(0,0,0,0.1);
}

.logo {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}
