@import '../../../vars';

.container {
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-left: 1.5rem;
  font-size: .85rem;
  padding-top: .4rem;
}

.name {
  font-weight: bold;
  color: rgba($text-white, 50%);
  margin-right: .8rem;
}

.control {
  font-weight: bold;
  color: $text-white;
}
.control:hover {
  cursor: pointer;
}

.dropdownIcon {
  margin-left: .7rem;
  height: .4rem;
}
.modal {
  z-index: 10;
  position: absolute;
  top: 2.5rem;
  left: 4rem;
  max-height: 10rem;
  width: 15rem;
  overflow-y: scroll;
  background-color: rgba($bg-dark, 0.95);
  border-radius: 1rem;
  display: flex;
  flex-direction: column;
  padding: 1.3rem;
  box-sizing: border-box;
}
.optionContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 1rem;
}
.optionContainer:last-child {
  margin-bottom: 0;
}
.optionContainer:hover {
  cursor: pointer;
}
.optionSelector {
  height: .4rem;
  aspect-ratio: 1;
  border-radius: .5rem;
  border: .3px solid rgba($bg-white, .8);
  margin-right: .7rem;
}
.optionSelectorSelected {
  border: none;
  background-color: $bg-white;
}
.optionText {
  color: $text-white;
  font-size: .9rem;
  font-weight: 600;
}
