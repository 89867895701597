@import '../../../vars';

.container {
  position: relative;
  border-radius: 0.4rem;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 4rem;
  height: 4rem;
}

.background {
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
}
.text {
  width: 60%;
  align-self: center;
  z-index: 2;
}
