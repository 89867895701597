@import '../../../vars';

.container {
  position: relative;
  border-radius: .5rem;
  display: flex;
  align-items: flex-end;
  width: 15rem;
  aspect-ratio: 0.65;
  background-color: $bg-medium;
  text-decoration: none;
  padding: 1.8rem;
  overflow: hidden;
  box-sizing: border-box;
}
.container:hover {
  cursor: pointer;
  opacity: 0.95;
}

.imageBg {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 0;
  object-fit: cover;
}

.shadow {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  background: linear-gradient(0deg, #000000ee, #00000033, #00000000);
}

.infosContainer {
  display: flex;
  flex-direction: column;
  z-index: 2;
}

.squaresContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 8.5rem;
}

.title {
  color: $text-white;
  font-size: 1.6rem;
  margin-block: 0;
  margin-top: 0.8rem;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  line-clamp: 2;
  -webkit-box-orient: vertical;
  max-width: 90%;
  text-overflow: ellipsis;
}

.participants {
  display: flex;
  color: $text-white;
  font-size: 0.8rem;
  margin-top: 0.8rem;
  font-weight: bold;
  height: 0.8rem;
}
