@import '../../../vars';

.container {
  border-radius: 0.4rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 4rem;
  height: 4rem;
  background-color: $bg-white;
}

.date {
  color: $bg-dark;
  font-weight: bold;
  font-size: 1.5em;
  margin-block: 0;
}
.month {
  color: rgba($bg-dark, 0.5);
  font-weight: bold;
  font-size: 0.6em;
  letter-spacing: 0.1em;
  margin-block: 0;
}
