@import '../../../../vars.scss';

.formRow {
  display: flex;
  flex-direction: row;
}
.rowSpacer {
  width: 1rem;
}

.ticketsContainer {
  margin-bottom: 2rem;
}
.ticketForm {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  margin-bottom: 1.5rem;
}
.delete {
  position: relative;
  color: $text-white;
  font-size: .8rem;
  top: -.6rem;
  text-decoration: underline;
  cursor: pointer;
}
