@import '../../../vars';

.container {
  position: relative;
  left: -50px;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
}

.content {
  width: 43%;
  box-sizing: border-box;
  //background-color: $bg-lighter;
  display: flex;
  flex-direction: column;
}
