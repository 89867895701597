@import '../../../vars';

.container {
  position: relative;
  width: 100%;
  margin-bottom: 1rem;
}
.required {
  background-color: $burgundy;
  height: .7rem;
  width: .15rem;
  border-radius: .2rem;
  position: absolute;
  right: .7rem;
  top: 0;
  bottom: 0;
  margin: auto 0;
}
.error {
  position: absolute;
  top: .7rem;
  left: 1.6rem;
  color: $burgundy;
  font-size: .6rem;
  font-style: italic;
}

.selectContainer {
  width: 100%;
  box-sizing: border-box;
  background-color: $bg-white;
  border: none;
  border-radius: .3rem;
  font-size: 1rem;
  padding: 1.4rem 1.6rem;
  outline: none;
  color: $text-grey;
  font-family: $lato;
}
