@import '../../../vars';

.container {
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  margin-top: 2rem;
  padding-left: 4rem;
  box-sizing: border-box;
}
.number {
  background-color: $burgundy;
  font-size: .95rem;
  padding: .4rem;
  color: $text-white;
  font-weight: bold;
  border-radius: .3rem;
}
