@import '../../../../vars';

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.value {
  color: $text-white;
  font-size: 1.2rem;
  font-weight: 600;
  margin-bottom: .2rem;
}
.text {
  color: $text-white;
  font-size: .6rem;
}
