@import '../../../vars';

.header {
  position: relative;
  width: 100%;
  aspect-ratio: 1.05;
}

.headerImage {
  object-fit: cover;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.shadow {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  background: linear-gradient(0deg, #000000ee, #00000033, #00000000);
}

.headerInfosContainer {
  position: absolute;
  bottom: 0;
  left: 0;
  z-index: 2;
  padding: 0 4rem 2rem 4rem;
}

.squaresContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 10.5rem;
}
.square {
  height: 5rem;
  width: 5rem;
}

.headerTitle {
  color: $text-white;
  font-size: 2.5rem;
  margin-top: 1rem;
  margin-bottom: .5rem;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  line-clamp: 2;
  -webkit-box-orient: vertical;
}

.subtitle {
  color: $text-white;
  font-size: 0.9em;
}
.shareButtonContainer {
  max-width: 30%;
  //flex: 0.4;
}

.content {
  padding: 2rem 4rem 2rem 4rem;
  background-color: $bg-white;
}


.firstPart {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 2rem;
}

.title {
  font-weight: normal;
  font-size: 1.5em;
}

.stats {
}

.pax {
  font-size: 0.9em;
  color: $text-black;
  font-weight: 600;
}
.remaining {
  margin-top: 0.4rem;
  font-size: 0.9em;
  color: $text-gold;
  font-weight: 600;
}

.description {
  margin-top: 1rem;
  font-size: 0.9em;
  line-height: 1.5em;
  color: $bg-dark;
  margin-bottom: 2rem;
  white-space: pre-wrap;
}

.carousel {
  width: 100%;
  box-sizing: border-box;
  margin-bottom: 2rem;
}
.editButtonContainer {
  padding: 0 4rem 2rem 4rem;
  display: flex;
  flex-direction: column;
}
.spacer {
  height: 10px;
}

@media screen and (max-width: 800px) {
  .headerInfosContainer {
    padding: 0 2.5rem 2rem 2.5rem;
  }
  .content {
    padding: 2rem 2.5rem 2rem 2.5rem;
  }
  .shareButtonContainer {
    max-width: 50%;
  }
}
