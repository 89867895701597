@import '../../../vars';

.container {
  width: 100%;
  padding: 1rem 1rem;
  background-color: $bg-white;
  border-radius: .3rem;
  overflow: hidden;
  margin-bottom: .8rem;
  box-sizing: border-box;
}
