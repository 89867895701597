@import '../../../vars';

.container {
  position: relative;
  border-radius: .5rem;
  display: flex;
  align-items: flex-end;
  width: 15rem;
  aspect-ratio: 0.65;
  background-color: $bg-medium;
  text-decoration: none;
  padding: 1.2rem;
  overflow: hidden;
  box-sizing: border-box;
}
.container:hover {
  cursor: pointer;
  opacity: 0.95;
}

.imageBg {
  object-fit: cover;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 0;
}

.shadow {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  background: linear-gradient(45deg, #000000aa, #00000099, #00000066);
}

.header {
  position: absolute;
  top: 1.1rem;
  left: 1.1rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  z-index: 2;
}
.headerIcon {
  height: 1.4rem;
  width: 1.4rem;
  margin-right: .6rem;
}
.headerText {
  color: $text-white;
  font-weight: bold;
  text-transform: uppercase;
  font-size: .6rem;
}

.infosContainer {
  display: flex;
  flex-direction: column;
  z-index: 2;
}
.sphere {
  color: $text-white;
  text-transform: uppercase;
  font-weight: bold;
  margin-bottom: .6rem;
  font-size: .6rem;
}
.title {
  color: $text-white;
  margin-bottom: .5rem;
  font-size: 1.4rem;
  line-height: 1.7rem;
  font-weight: 600;
  overflow: hidden;
  height: 6.8rem;
}
.introduction {
  color: $text-white;
  margin-bottom: .5rem;
  font-size: .8rem;
  line-height: 1rem;
  overflow: hidden;
  //display: -webkit-box;
  //-webkit-line-clamp: 3;
  //line-clamp: 3;
  //-webkit-box-orient: vertical;
  height: 4rem;
}

.author {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 3rem;
}
.square {
  height: 3rem;
  width: 3rem;
  //border: 1px solid $bg-white;
}
.authorText {
  display: flex;
  flex-direction: column;
  margin-left: .6rem;
}
.authorName {
  color: $text-white;
  font-weight: bold;
  font-size: .6rem;
  margin-bottom: .2rem;
}
.authorJob {
  color: $text-white;
  font-style: italic;
  font-size: .6rem;
}

.statsContainer {
  display: flex;
  flex-direction: column;
  margin-top: .4rem;
}
