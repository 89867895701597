.container {
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow-y: scroll;
  box-sizing: border-box;
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}
.container::-webkit-scrollbar {
  display: none;
}
.container > *:first-child {
  margin-top: 3rem;
}
.container > *:last-child {
  margin-bottom: 3rem;
}

.centeredWrapper {
  width: 100%;
  padding-left: 4rem;
  padding-right: 4rem;
  box-sizing: border-box;
}
