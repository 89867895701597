@import '../../../../vars';

.container {
  margin-bottom: 2rem;
}
.square {
  height: 5rem;
  width: 5rem;
}
.infosContainer {
  margin-top: 1rem;
  display: flex;
  flex-direction: row;
  align-items: center;
}
.textContainer {
  display: flex;
  margin-left: 1rem;
  flex-direction: column;
  max-width: 80%;
}
.name {
  margin-bottom: 0.2rem;
  font-weight: bold;
  color: $text-black;
}
.infos {
  color: $text-black;
  font-size: 0.8rem;
}

.description {
  margin-top: 1rem;
  font-size: 0.8rem;
  color: $text-grey;
  line-height: 1.2rem;
  white-space: pre-wrap;
}
