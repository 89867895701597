@import '../../../vars';

.container {
  position: relative;
  width: 100%;
  margin-bottom: 1rem;
}
.required {
  background-color: $burgundy;
  height: .7rem;
  width: .15rem;
  border-radius: .2rem;
  position: absolute;
  right: .7rem;
  top: 0;
  bottom: 0;
  margin: auto 0;
}
.error {
  position: absolute;
  top: .7rem;
  left: 1.6rem;
  color: $burgundy;
  font-size: .6rem;
  font-style: italic;
}

.filePicker {
  width: 100%;
  border: .5px solid $bg-lighter;
  box-sizing: border-box;
  border-radius: .5rem;
  overflow: hidden;
  font-size: 1rem;
  outline: none;
  display: flex;
  align-items: center;
  padding: 1.4rem 1.6rem;
  cursor: pointer;
}
.noBorder {
  border: none;
}
.button {
  height: 10rem;
}
.content {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.image {
  height: 100%;
  aspect-ratio: 1;
  object-fit: cover;
}
.fileIcon {
  height: 1.2rem;
  box-sizing: border-box;
}
.placeholder {
  font-size: .8rem;
  margin-left: .5rem;
  color: $bg-white;
}
