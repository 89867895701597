@import '../../../vars';

.overlay {
  position: fixed;
  inset: 0px;
  background-color: rgba($bg-dark, 0.9);
  z-index: 35;
}

.modal {
  width: 20%;
  height: 20%;
  position: absolute;
  top: 40%;
  left: 40%;
  right: auto;
  bottom: auto;
  outline: none;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.validationIcon {
  height: 5rem;
  aspect-ratio: 1;
}
.text {
  margin-top: 1.5rem;
  color: $text-white;
  font-weight: bold;
  font-size: 1.2rem;
  text-align: center;
  line-height: 2rem;
}
@media screen and (max-width: 800px) {
  .modal {
    width: 60%;
    height: 60%;
    position: absolute;
    top: 20%;
    left: 20%;
  }
}
