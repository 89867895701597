@import '../../../vars';

.container {
  width: 100%;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  margin-top: 1rem;
}
.titleContainer {
  margin: auto;
  width: auto;
  display: flex;
  align-items: center;
  margin-bottom: 1rem;
  margin-top: 2rem;
  margin-left: 4rem;
}
.title {
  color: $text-white;
  font-size: 1.2rem;
  text-decoration: none;
  font-weight: bold;
}
.icon {
  width: 2rem;
  aspect-ratio: 1;
  margin-right: 1rem;
}
.arrowRight {
  margin-top: 0.3rem;
  height: 0.7rem;
  aspect-ratio: 1;
  margin-left: 0.5rem;
}

.carouselContainer {
  position: relative;
}
.carousel {
  width: 100%;
  min-height: 50px;
  overflow-x: scroll;
  display: flex;
  flex-direction: row;
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
  padding-left: 4rem;
  padding-right: 4rem;
  box-sizing: border-box;
}
.carousel::-webkit-scrollbar {
  display: none;
}

.cardWrapper {
  box-sizing: border-box;
  margin-right: .8rem;
}
.cardWrapper:last-child {
  box-sizing: border-box;
  margin-right: 0;
}

.rightArrowContainer {
  position: absolute;
  top: 0;
  right: 0;
  width: 7rem;
  height: 100%;
  background: radial-gradient(ellipse at right, #000000ff, #00000044 50%, #00000000 70%);
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding-right: .5rem;
  pointer-events: all;
  z-index: 4;
}
.rightArrow {
  width: 1rem;
  opacity: .8;
  pointer-events: all;
  cursor: pointer;
  padding: 1rem;
}

.leftArrowContainer {
  position: absolute;
  top: 0;
  left: 0;
  width: 7rem;
  height: 100%;
  background: radial-gradient(ellipse at left, #000000ff, #00000044 50%, #00000000 70%);
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding-left: .5rem;
  pointer-events: all;
  z-index: 4;
}
.leftArrow {
  width: 1rem;
  opacity: .8;
  pointer-events: all;
  cursor: pointer;
  padding: 1rem;
  transform: rotate(180deg);
}

.cta {
  font-weight: bold;
  color: $text-orange;
  font-size: .8rem;
  margin-left: .4rem;
  margin-top: .3rem;
}
