@import '../../../vars';

.container {
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  box-sizing: border-box;
}

.content {
  max-width: 100%;
  min-height: 100%;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 32px;
}
.content::-webkit-scrollbar {
  display: none;
}

.shadow {
  position: fixed;
  bottom: 0;
  left: 50px;
  width: 100%;
  height: 3rem;
  background: linear-gradient(0deg, #00000088, #00000033, #00000000);
  pointer-events: none;
  box-sizing: border-box;
}
