@import '../../../vars';

.container {
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  color: $text-black;
}

.squaresContainer {
  flex: 0.26;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}
.dateSquare {
  box-shadow: 0 2px 5px 1px rgba(0,0,0,0.1);
}

.infosContainer {
  position: relative;
  flex: 0.55;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  box-sizing: border-box;
  margin-left: .5rem;
}
.eventInfos {
  display: flex;
  flex-direction: column;
  max-width: 60%;
}
.name {
  font-weight: bold;
  font-size: 1rem;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  line-clamp: 2;
  -webkit-box-orient: vertical;
}
.address {
  font-style: italic;
  font-size: 0.6rem;
  margin-top: .2rem;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  line-clamp: 1;
  -webkit-box-orient: vertical;
}

.participantsContainer {
  display: flex;
  flex-direction: column;
  max-width: 25%;
}
.participantsTitle {
  font-weight: bold;
  font-size: .6rem;
}
.participants {
  margin-top: .2rem;
  font-size: 1rem;
  font-weight: bold;
}

.arrowRight {
  height: .8rem;
  cursor: pointer;
}

.editIconContainer {
  flex: 0.15;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}
.editContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.editIcon {
  height: 2.3rem;
  aspect-ratio: 1;
  cursor: pointer;
}
