@import '../../../vars';

.container {
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  color: $text-black;
}

.squaresContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
}

.infosContainer {
  cursor: pointer;
  position: relative;
  flex: 0.4;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  box-sizing: border-box;
  margin-left: 1rem;
}
.icon {
  height: .8rem;
  margin-right: .3rem;
  margin-bottom: -.1rem;
}
.type {
  font-size: .8rem;
  font-weight: bold;
}
.eventInfos {
  display: flex;
  flex-direction: column;
  max-width: 85%;
}
.name {
  margin-top: .4em;
  font-weight: bold;
  font-size: 1rem;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  line-clamp: 2;
  -webkit-box-orient: vertical;
}
.address {
  font-style: italic;
  font-size: 0.6rem;
  margin-top: .2rem;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  line-clamp: 1;
  -webkit-box-orient: vertical;
}
.arrowRight {
  height: .8rem;
  cursor: pointer;
}

.editIconContainer {
  position: absolute;
  right: 0;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}
.buttonSpacer {
  width: 1rem;
}

.statsContainer {
  flex: 0.35;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
.statColumn {
  display: flex;
  flex-direction: column;
  margin-left: 1rem;
}
.statsTitle {
  font-size: .6rem;
  font-weight: bold;
}
.statsNumber {
  font-size: 1rem;
  font-weight: bold;
  margin-top: .5rem;
}
