@import '../../../vars';

.form {
  padding-top: 3rem;
  padding-bottom: 5rem;
  width: 100%;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}
.form::-webkit-scrollbar {
  display: none;
}

.header {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding-bottom: 2rem;
  box-sizing: border-box;
}
.alert {
  font-size: .9rem;
  font-style: italic;
  color: rgba($burgundy, .8);
  margin-top: .5rem;
}

.formRow {
  display: flex;
  flex-direction: row;
}
.rowSpacer {
  width: 1rem;
}

.companyRow {
  height: 9rem;
  display: flex;
  flex-direction: row;
  margin-bottom: 1rem;
}
.logo {
  display: flex;
  height: 100%;
  aspect-ratio: 1;
  margin-right: 1rem;
}
.companyFields {
  flex: 1;
  display: flex;
  flex-direction: column;
  margin-bottom: -1rem;
}

.submitButton {
  margin-top: 2rem;
}
.cancelButton {
  margin-top: 1rem;
}
