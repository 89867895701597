@import '../../../vars';

.searchContainer {
  border-radius: .3rem;
  border: 1px solid $bg-white;
  display: flex;
  flex-direction: row;
  align-items: center;
  box-sizing: border-box;
}
.searchIcon {
  height: .8rem;
  margin: .5rem;
}
.searchInput {
  background-color: transparent;
  border: none;
  height: 100%;
  width: 9rem;
  outline: none;
  color: $text-white;
  font-family: $lato;
  vertical-align: center;
  margin-top: -1px;
  font-size: 1rem;
}
.searchInput::placeholder {
  color: rgba($text-white, 0.7);
  font-weight: 500;
}
