@import '../../../vars';

.container {
  position: relative;
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.form {
  width: 30%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.button {
  width: 100%;
}
.button:last-child {
  margin-top: .6rem;
}

.title {
  color: $text-white;
  margin-bottom: 2rem;
  font-size: 1.7rem;
}

.icons {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: center;
  margin-bottom: 4rem;
}
.textIcon {
  height: 47px;
}
.icon {
  height: 40px;
  margin-left: 12px;
}

.forgotPassword {
  color: $text-white;
  border-bottom: .7px solid $text-white;
  padding-bottom: 3px;
  align-self: self-start;
  margin-left: 1.6rem;
  box-sizing: border-box;
  margin-bottom: 2rem;
  font-size: 0.8rem;
}

.createAccount {
  position: absolute;
  bottom: 2rem;
  font-size: .9rem;
  color: $text-white;
  padding-bottom: .4rem;
  border-bottom: 1px solid $bg-white;
}
.createAccount:hover {
  cursor: pointer;
  opacity: .8;
}
@media screen and (max-width: 800px) {
  .form {
    width: 90%;
  }
  .createAccount {
    position: unset;
    font-size: 1.2rem;
    font-weight: bold;
    color: $text-white;
    padding-bottom: .4rem;
    border-bottom: 2px solid $bg-white;
  }
  .createAccount:hover {
    cursor: pointer;
    opacity: .8;
  }
}
