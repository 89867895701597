@import '../../../../vars';

.container {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  height: 1.5rem;
  border-radius: .3rem;
  background-color: $bg-lighter;
  margin-top: .4rem;
}

.valueContainer {
  flex: 0.6;
  background-color: $burgundy;
  border-radius: .3rem;
  padding-left: 1rem;
  display: flex;
  flex-direction: row;
  align-items: center;
}
.value {
  color: $text-white;
  font-size: .7rem;
  font-style: italic;
  font-weight: 600;
}
.background {
  flex: 0.5;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  padding-right: .8rem;
  align-items: center;
}
.text {
  color: $text-black;
  font-weight: bold;
  font-size: .6rem;
}
