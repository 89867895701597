@import '../../../vars';

.container {
  position: relative;
  width: 100%;
  min-height: 35rem;
  overflow: hidden;
  border-radius: .5rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  box-sizing: border-box;
  box-shadow: 0px 2px 10px 5px rgba(0,0,0,0.3);
  padding: 1rem;
  padding-left: 6.5rem;
}
.image {
  object-fit: center;
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  border-radius: 1rem;
  box-sizing: border-box;
  //width: 100%;
}
.shadow {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background: linear-gradient(90deg, #111111ff, #111111aa 60%, #11111166);
}
.infosContainer {
  z-index: 1;
  width: 25%;
}
.surname {
  font-family: $alexander-lettering;
  color: $text-white;
  font-size: 8rem;
  line-height: 8rem;
}
.name {
  font-size: 2.5rem;
  color: $text-white;
  font-weight: 900;
  margin-top: 1rem;
}
.name:last-of-type {
  margin-top: .2rem;
  margin-bottom: 1rem;
}
.job {
  font-size: 1.5rem;
  color: $text-white;
  line-height: 2rem;
}

.buttonContainer {
  margin-top: 2rem;
  display: flex;
  width: 80%;
}

.cardsContainer {
  width: 55%;
  height: 100%;
  position: absolute;
  right: 0;
  top: 0;
  padding: 2rem;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  box-sizing: border-box;
}

.card1 {
  position: absolute;
  left: 0;
  width: 45%;
  background-color: #0b2e13;
  z-index: 9;
  aspect-ratio: 0.7;
  border-radius: .5rem;
  box-shadow: 0px 2px 9px 10px rgba(0,0,0,0.3);
  cursor: pointer;
}
.card2 {
  position: absolute;
  left: 30%;
  width: 37%;
  background-color: #0e90d2;
  z-index: 8;
  aspect-ratio: 0.7;
  border-radius: .5rem;
  box-shadow: 0px 2px 10px 10px rgba(0,0,0,0.2);
  cursor: pointer;
}
.card3 {
  position: absolute;
  left: 53%;
  width: 30%;
  background-color: white;
  z-index: 7;
  aspect-ratio: 0.7;
  border-radius: .5rem;
  box-shadow: 0px 2px 10px 10px rgba(0,0,0,0.1);
  cursor: pointer;
}

.user {
  position: absolute;
  top: 0;
  left: 0;
  object-fit: cover;
  width: 100%;
  height: 100%;
  border-radius: .5rem;
}
