@import '../../../vars';

.container {
  margin-bottom: 1rem;
}
.name {
  font-size: .8rem;
  color: $burgundy;
  margin-top: .5rem;
}

.description {
  color: $text-grey;
  font-size: 0.8rem;
  margin-top: .5rem;
  white-space: pre-wrap;
}
