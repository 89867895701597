@import '../../vars.scss';

.container {
  position: absolute;
  top: 0;
  left: 0;
  width: 100px;
  height: 100vh;
  z-index: 20;
  display: flex;
  flex-direction: row;
}
.shadow {
  height: 100%;
  width: 800px;
  background: linear-gradient(90deg, #00000055, #00000033, #00000011, #00000000);
}
.openContainer {
  width: auto;
}
.nav {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  box-sizing: border-box;
  padding-top: 2.7rem;
  background-color: $bg-dark;
}
.topContainer {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.iconMenu {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-left: 30px;
  width: 100%;
}
.homeIcon {
  width: 40px;
  margin-top: .3rem;
}
.icon {
  width: 22px;
}
.textIcon {
  width: 60px;
  margin-left: 10px;
}

.menuContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  box-sizing: border-box;
  margin: 5vh 39px 0 39px;
}

.menu {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-bottom: 35px;
}
.menuLine {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
}
.menuLine:hover {
  cursor: pointer;
}
.menuName {
  text-decoration: none;
  color: $bg-grey;
  font-weight: 700;
  margin-left: 10px;
  font-size: 1rem;
}
.menuNameWhite {
  color: $text-white;
}
.subMenuList {
  display: flex;
  flex-direction: column;
  margin-left: 32px;
  margin-top: 15px;
}
.subMenu {
  margin-bottom: 15px;
  font-size: 0.9rem;
  color: $bg-grey;
}
.subMenu:hover {
  color: $text-white;
  font-weight: bold;
}
.subMenu:last-child {
  margin-bottom: 0;
}
.subMenuActive {
  color: $text-white;
  font-weight: bold;
}
.icon:hover .homeIcon:hover {
  cursor: pointer;
}
.recommendationsIcon {
  width: 20px;
}

.bottomContainer {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  box-sizing: border-box;
  padding: 0 25px 0 25px;
  width: 100%;
}

.notificationsIconContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-left: 13px;
  margin-bottom: 1.5rem;
  margin-top: 1.5rem;
}

.separator {
  position: relative;
  height: .5px;
  left: -25px;
  width: calc(100% + 50px);
  background-color: $bg-light;
}

.profileContainer {
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 1rem;
  cursor: pointer;
}
.profile {
  border-radius: 5px;
  height: 50px;
  width: 50px;
  object-fit: cover;
  cursor: pointer;
}
.profileText {
  font-size: .8rem;
  line-height: 1.2rem;
  font-weight: bold;
  //margin-left: 5px;
}

.logout {
  margin-top: 2rem;
  color: $text-white;
  font-size: 0.6rem;
  line-height: .6rem;
  cursor: pointer;
  text-decoration: underline;
}

.footer {
  margin-top: 2rem;
  margin-bottom: 2rem;
  display: flex;
  justify-content: space-between;
  width: 100%;
  color: $text-white;
  font-size: 0.5rem;
  line-height: .6rem;
}
.copyright {
}
.cgu {
  cursor: pointer;
  text-decoration: underline;
}

.accountModal {
  position: absolute;
  left: calc(100% + 25px);
  bottom: -4.6rem;
  //top: 0px;
  //transform: translate(100%);
  background-color: $bg-medium;
  padding: 1rem;
  border-radius: .5rem;
  z-index: 10;
}

.podcastContainer {
  //justify-content: center;
  height: 2rem;
  margin-bottom: 1rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-left: 13px;
}
.podcast {
  width: 2rem;
  height: 100%;
  background-color: $burgundy;
  border-radius: .3rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  aspect-ratio: 1;
  padding: 0 .4rem;
  box-sizing: border-box;
  cursor: pointer;
}
.podcastOpen {
  width: 100%;
}
.podcastIcon {
  height: 1rem;
  margin-left: .1rem;
}
.podcastName {
  color: $text-white;
  font-weight: 600;
  font-size: .7rem;
  margin-left: .5rem;
  line-height: 1rem;
  height: 1rem;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
