@import '../../../vars';

.container {
  position: relative;
  width: 100%;
  min-height: 35rem;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-left: 6.5rem;
  box-sizing: border-box;
  border-radius: .5rem;
  box-shadow: 10px 10px 10px 3px rgba(0,0,0,0.1);
}
.image {
  object-fit: cover;
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
}
.shadow {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background: linear-gradient(85deg, #000000bb, #00000055, #00000000);
}

.logo {
  height: 6rem;
  width: 6rem;
}

.infosContainer {
  z-index: 1;
  max-width: 60%;
}
.title {
  font-size: 2.5rem;
  color: $text-white;
  font-weight: 900;
  margin-top: 2rem;
}
.description {
  margin-top: 2rem;
  color: $text-white;
  font-size: 1.4rem;
  line-height: 2.3rem;
  max-width: 70%;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  line-clamp: 3;
  -webkit-box-orient: vertical;
}

.buttonContainer {
  margin-top: 2rem;
  display: flex;
  width: 30%;
}
