@import '../../../vars';

.container {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 1.5rem;
}
.container:last-child {
  margin-bottom: 0;
}
.container:hover {
  cursor: pointer;
}
.iconContainer {
  width: 2rem;
  display: flex;
  justify-content: center;
  margin-right: .4rem;
}
.icon {
}
.text {
  color: $text-white;
  font-size: .9rem;
}
