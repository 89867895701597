.outletContainer {
  height: 100%;
  margin-left: 100px;
  flex-grow: 1;
  overflow-y: scroll;
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}

.authContainer {
  position: relative;
  height: 100vh;
}
.authOutletContainer {
  height: 100%;
}
