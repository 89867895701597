@import '../../../vars';

.container {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  box-sizing: border-box;
}
.tab {
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1;
  height: 3rem;
  margin-right: .5rem;
  cursor: pointer;
  background-color: $bg-white;
  border-radius: .3rem;
}
.tab:last-child {
  margin-right: 0;
}
.tabName {
  color: $text-black;
  font-weight: bold;
  font-size: 1rem;
}
.active {
  background-color: $burgundy;
  color: $text-white;
}
