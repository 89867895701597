@import '../../../vars';

.overlay {
  position: fixed;
  inset: 0px;
  z-index: 40;
  pointer-events: none;
}

.modal {
  position: absolute;
  top: 2rem;
  right: 2rem;
  min-width: 15rem;
  max-width: 20rem;
  outline: none;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  background-color: $bg-white;
  padding: 1rem;
  border-radius: .5rem;
  box-sizing: border-box;
}

.closeModalContainer {
  position: absolute;
  top: 1rem;
  right: 1rem;
  height: .6rem;
  width: .6rem;
  cursor: pointer;
  pointer-events: fill;
}
.closeModal {
}


.error {
  color: $burgundy;
  font-weight: bold;
  font-size: 1rem;
  margin-bottom: .4rem;
  margin-top: -0.2rem;
}

.text {
  color: $text-grey;
  font-weight: bold;
  font-size: .9rem;
  max-width: 90%;
}
