@import '../../../vars';

.container {
  margin-top: 1rem;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.text {
  color: $text-white;
  font-size: 0.7rem;
}
.darkText {
  color: $text-black;
}

.locationIcon {
  height: 0.7rem;
  margin-right: 0.4rem;
}
