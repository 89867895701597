@import '../../../../vars';

.overlay {
  position: fixed;
  inset: 0px;
  background-color: rgba($bg-dark, 0.9);
  z-index: 30;
}

.modal {
  width: 40%;
  height: 100vh;
  position: absolute;
  top: 0;
  left: 50%;
  right: auto;
  bottom: auto;
  transform: translate(-50%, 0);
  outline: none;
}

.container {
  width: 100%;
  height: 100%;
  padding-top: 3rem;
  padding-bottom: 5rem;
  box-sizing: border-box;
  overflow-y: scroll;
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}
.container::-webkit-scrollbar {
  display: none;
}

.closeModalContainer {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  margin-bottom: 1rem;
}
.closeModal {
  margin-right: 1rem;
  height: 1.3rem;
  cursor: pointer;
}

.content {
  position: relative;
  background-color: $bg-lighter;
  box-sizing: border-box;
  width: 100%;
  border-radius: 1.1rem;
  overflow: hidden;
}

.header {
  width: 100%;
  padding: 2rem 4rem;
  background-color: $bg-lighter;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  align-items: center;
}
.headerLogo {
  height: 5rem;
  width: 5rem;
}

.headerInfos {
  margin-left: 1.5rem;
}
.headerGroup {
  font-size: 0.8rem;
  margin-top: .2rem;
  color: $text-grey;
}
.headerTitle {
  color: $text-black;
  font-size: 1.3rem;
}

.form {
  padding: 2rem 4rem;
  width: 100%;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
}
.formTitle {
  font-weight: bold;
  font-size: 1rem;
  margin-left: 1.4rem;
  margin-bottom: 1rem;
}
.formFooter {
  margin: 0 1.4rem 1rem 1.4rem;
  font-style: italic;
  font-size: .7rem;
  color: $text-grey;
}
