@import '../../../vars';

.container {
  position: relative;
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.form {
  width: 30%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.button {
  width: 100%;
}
.button:last-child {
  margin-top: .6rem;
}

.title {
  color: $text-white;
  margin-bottom: 2rem;
  font-size: 1.7rem;
}

.icons {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: center;
  margin-bottom: 4rem;
}
.textIcon {
  height: 47px;
}
.icon {
  height: 40px;
  margin-left: 12px;
}

.back {
  font-size: .9rem;
  color: $text-white;
  padding-bottom: .2rem;
  border-bottom: 1px solid $bg-white;
  margin-top: .5rem;
}
.back:hover {
  cursor: pointer;
  opacity: .8;
}

.login {
  position: absolute;
  bottom: 2rem;
  font-size: .9rem;
  color: $text-white;
  padding-bottom: .4rem;
  border-bottom: 1px solid $bg-white;
}
.login:hover {
  cursor: pointer;
  opacity: .8;
}

.description {
  display: flex;
  flex-direction: row;
  align-items: center;
  color: $text-black;
  justify-content: space-between;
  width: 100%;
  padding: 1rem 1.6rem;
  box-sizing: border-box;
  background-color: $bg-white;
  border-radius: .3rem;
  margin-top: 1rem;
}
.leftDescription {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.leftTitle {
  margin-bottom: .5rem;
  font-size: 1.2rem;
  font-weight: bold;
}
.zone {
  font-size: .8rem;
}
.rightDescription {
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: right;
}
.price {
  color: $burgundy;
  font-weight: bold;
  font-size: 1.3rem;
}
.ht {
  color: $text-black;
  font-size: 1rem;
  margin-left: .2rem;
}
.specialOffer {
  font-size: .7rem;
  font-style: italic;
  margin-top: .2rem;
}
.renewal {
  margin-top: .3rem;
  font-size: .8rem;
}
.paragraph {
  color: $text-white;
  font-size: .8rem;
  font-style: italic;
  max-width: 90%;
  text-align: center;
  margin-top: 1rem;
  margin-bottom: 2rem;
}
.cgu {
  color: $text-white;
  font-size: .8rem;
  font-style: italic;
  max-width: 90%;
  text-align: center;
  margin-top: 1rem;
}
.cguLink {
  font-weight: bold;
  text-decoration: underline;
}

@media screen and (max-width: 800px) {
  .form {
    width: 90%;
  }
}
