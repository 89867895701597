@import '../../../vars';

.overlay {
  position: fixed;
  inset: 0px;
  background-color: rgba($bg-dark, 0.9);
  z-index: 30;
}

.modal {
  width: 30rem;
  height: 100vh;
  position: absolute;
  top: 0;
  left: 50%;
  right: auto;
  bottom: auto;
  transform: translate(-50%, 0);
  outline: none;
}

.container {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  overflow-y: scroll;
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}
.container::-webkit-scrollbar {
  display: none;
}

.content {
  position: relative;
  background-color: $bg-lighter;
  box-sizing: border-box;
  width: 100%;
  padding: 1.5rem;
  border-radius: .5rem;
  display: flex;
  flex-direction: column;
}

.form {
  display: flex;
  flex-direction: column;
}
.closeModalContainer {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  margin-bottom: 1rem;
}
.closeModal {
  margin-right: 1rem;
  height: 1.3rem;
  cursor: pointer;
}
