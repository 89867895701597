@import '../../../vars';

.container {
  position: relative;
  border-radius: .5rem;
  display: flex;
  align-items: flex-end;
  width: 15rem;
  aspect-ratio: 0.65;
  background-color: $bg-medium;
  text-decoration: none;
  padding: 1.8rem;
  overflow: hidden;
  box-sizing: border-box;
}
.container:hover {
  cursor: pointer;
  opacity: 0.95;
}

.imageBg {
  object-fit: cover;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 0;
}

.shadow {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  background: linear-gradient(0deg, #000000ee, #00000033, #00000000);
}

.infosContainer {
  display: flex;
  flex-direction: column;
  z-index: 2;
}

.surname {
  font-family: 'AlexanderLettering';
  color: $text-white;
  font-size: 5rem;
  line-height: 5rem;
  margin-block: 0;
  // margin-bottom: 0.5rem;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 1; /* number of lines to show */
  line-clamp: 1;
  -webkit-box-orient: vertical;
}

.name {
  color: $text-white;
  font-size: 1.8rem;
  margin-block: 0;
  //margin-top: 0.8rem;
  margin-bottom: 0.4rem;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2; /* number of lines to show */
  line-clamp: 2;
  -webkit-box-orient: vertical;
}

.job {
  color: $text-white;
  font-size: 0.8rem;
  margin-bottom: 0.2rem;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  line-clamp: 1;
  -webkit-box-orient: vertical;
}
