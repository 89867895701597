@import '../../../vars';

.title {
  margin-left: 1.4rem;
  font-size: 1rem;
  color: $text-white;
  font-weight: bold;
  margin-bottom: .7rem;
  margin-top: 1rem;
}
