@import '../../../vars';

.overlay {
  position: fixed;
  inset: 0px;
  background-color: rgba($bg-dark, 0.9);
  z-index: 25;
}

.modal {
  width: 100rem;
  height: 100vh;
  position: absolute;
  top: 0;
  left: 50%;
  right: auto;
  bottom: auto;
  transform: translate(-50%, 0);
  outline: none;
}

.container {
  width: 100%;
  height: 100%;
  padding-top: 3rem;
  padding-bottom: 5rem;
  padding-left: 30%;
  padding-right: 30%;
  box-sizing: border-box;
  overflow-y: scroll;
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}
.container::-webkit-scrollbar {
  display: none;
}

.closeModalContainer {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  margin-bottom: 1rem;
}
.closeModal {
  margin-right: 1rem;
  height: 1.3rem;
  cursor: pointer;
}

.content {
  position: relative;
  background-color: $text-white;
  box-sizing: border-box;
  width: 100%;
  border-radius: 1.1rem;
  overflow: hidden;
}

@media screen and (max-width: 800px) {
  .modal {
    width: 100%;
    height: 100%;
    transform: none;
    left: 0;
  }
  .container {
    padding: 0;
  }
  .content {
    border-radius: 0;
  }
}
