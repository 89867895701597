@import '../../../../vars';

.container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  flex: 1;
  height: 100%;
}

.number {
  color: $burgundy;
  font-weight: bold;
  font-size: 1.4rem;
}
.text {
  color: $bg-dark;
  font-size: 1.4rem;
}
