@import '../../../vars';

.button {
  position: relative;
  border-radius: .3rem;
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 2.6rem;
  max-height: 2.6rem;
  aspect-ratio: 1;
  background-color: $bg-white;
  box-shadow: 0 2px 5px 1px rgba(0, 0, 0, 0.1);
  border: none;
  outline: none;
}
.button:hover {
  cursor: pointer;
  opacity: 0.7;
}
.flexStart {
  justify-content: flex-start;
  padding-right: 1rem;
}

.icon {
  height: 1rem;
}
.legend {
  position: absolute;
  top: 2.8rem;
  color: $text-grey;
  font-weight: bold;
  font-size: 0.6em;
  line-height: .6rem;
  //overflow: hidden;
  //display: -webkit-box;
  //-webkit-line-clamp: 1;
  //line-clamp: 1;
  //-webkit-box-orient: vertical;
  //margin-top: .5rem;
}
