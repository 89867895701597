@import '../../../vars';

.container {
  margin-top: 1rem;
  background-color: $bg-lighter;
  padding: 1rem;
  border-radius: .5rem;
}
.title {
  font-weight: bold;
  margin-bottom: .7rem;
  font-size: 1rem;
  color: $text-grey;
}
.content {
  white-space: pre-wrap;
  color: $text-grey;
  font-size: .9rem;
  line-height: 1.5rem;
}
