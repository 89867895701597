@import '../../../vars';

.container {
  position: relative;
  width: 100%;
  margin-bottom: 1rem;
}
.required {
  background-color: $burgundy;
  height: .7rem;
  width: .15rem;
  border-radius: .2rem;
  position: absolute;
  right: .7rem;
  top: 0;
  bottom: 0;
  margin: auto 0;
}
.error {
  position: absolute;
  top: .7rem;
  left: 1.6rem;
  color: $burgundy;
  font-size: .6rem;
  font-style: italic;
}

.imagePicker {
  width: 100%;
  aspect-ratio: 1;
  border: .5px solid $bg-lighter;
  box-sizing: border-box;
  border-radius: .5rem;
  overflow: hidden;
  font-size: 1rem;
  outline: none;
  display: flex;
  align-items: center;
  justify-content: center;
}
.noBorder {
  border: none;
}

.image {
  height: 100%;
  aspect-ratio: 1;
  object-fit: cover;
  z-index: 1;
}

.content {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 10%;
  box-sizing: border-box;
  cursor: pointer;
}
.addIcon {
  padding: 5%;
  height: 10%;
  aspect-ratio: 1;
  border-radius: 100%;
  border: 1.5px solid $bg-lighter;
}
.placeholder {
  margin-top: 5%;
  color: $bg-lighter;
  font-weight: bold;
}

.editIconContainer {
  position: absolute;
  top: 1rem;
  right: 1rem;
  background-color: rgba($bg-white, 0.8);
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 2rem;
  width: 2rem;
  cursor: pointer;
  z-index: 2;
}
.editIcon {
  height: 1rem;
}
