$bg-dark: #141414;
$bg-medium: #262626;
$bg-light: #404040;
$bg-grey: #D8D8D8;
$bg-lighter: #F2F2F2;
$bg-white: #ffffff;
$text-white: #ffffff;
$text-gold: #dba246;
$text-orange: #f4801c;
$text-black: #3e3e3e;
$text-grey: #5d5d5d;
$burgundy: #DD2944;
$green: #28CB80;

$alexander-lettering: 'AlexanderLettering';
$lato: 'Lato';
