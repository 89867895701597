@import '../../../vars';

.container {
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 1rem 1rem;
  color: $text-black;
  background-color: $bg-white;
  border-radius: .3rem;
  margin-bottom: 1rem;
  box-sizing: border-box;
}
.image {
  height: 5rem;
  width: 5rem;
  object-fit: cover;
  border-radius: 0.3rem;
  overflow: hidden;
}

.infosContainer {
  flex: 0.55;
  display: flex;
  flex-direction: row;
  align-items: center;
  position: relative;
  cursor: pointer;
}
.infosText {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-left: 1rem;
}
.name {
  font-style: italic;
  font-size: .8rem;
  margin-top: .15rem;
}
.title {
  font-weight: bold;
  font-size: .9rem;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  line-clamp: 1;
  -webkit-box-orient: vertical;
}
.updatedOn {
  font-style: italic;
  font-size: 0.6rem;
  margin-top: .2rem;
}

.recosContainer {
  flex: 0.4;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}
.recoColumn {
  display: flex;
  flex-direction: column;
}
.recosTitle {
  font-size: .6rem;
  font-weight: bold;
  max-width: 3rem;
}
.recosNumber {
  font-size: 1rem;
  font-weight: bold;
  margin-top: .5rem;
}
.arrowRight {
  position: absolute;
  right: 0;
  height: .8rem;
}

.statusButtonContainer {
  flex: 0.7;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
}
.responseButtonsContainer {
  flex: 0.8;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
}
.rightButton {
  margin-left: .5rem;
}
