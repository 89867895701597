@import '../../../vars';

.header {
  width: 100%;
  display: flex;
  align-items: center;
  background-color: $bg-white;
  padding: 2rem 4rem;
  box-sizing: border-box;
}
.headerImage {
  height: 7rem;
  width: 7rem;
}
.headerInfos {
  flex: 1;
  width: 100%;
  height: 7rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-left: 1rem;
}
.headerName {
  margin-bottom: .2rem;
  font-weight: bold;
  font-size: 1.2rem;
  color: $text-grey;
}
.headerJob {
  font-size: .8rem;
  color: $text-grey;
  margin-bottom: .4rem;
}

.content {
  padding: 1.5rem 4rem 0 4rem;
  background-color: $bg-white;
  box-sizing: border-box;
}

.sphere {
  text-transform: uppercase;
  color: $text-black;
  font-weight: bold;
  font-size: .8rem;
}
.title {
  margin-top: .2rem;
  color: $text-black;
  font-weight: bold;
  font-size: 2.7rem;
  margin-bottom: .6rem;
}
.introduction {
  color: $text-black;
  line-height: 1.6rem;
  font-size: .9rem;
  margin-top: 2rem;
}
.dates {
  color: $text-grey;
  font-style: italic;
  font-size: .8rem;
}

.image {
  margin-top: 4rem;
  width: 100%;
  aspect-ratio: 2;
  object-fit: cover;
}

.bottomContainer {
  padding: 4rem 4rem 4rem 4rem;
  display: flex;
  flex: 1;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}
.spacer {
  flex: 0.1;
}

.publicFooter {
  height: 2rem;
}
