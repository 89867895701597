@import '../../../vars';

.overlay {
  position: fixed;
  inset: 0px;
  background-color: rgba($bg-dark, 0.9);
  z-index: 30;
}

.modal {
  width: 30%;
  height: 100vh;
  position: absolute;
  top: 0;
  left: 50%;
  right: auto;
  bottom: auto;
  transform: translate(-50%, 0);
  outline: none;
}

.container {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  overflow-y: scroll;
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}
.container::-webkit-scrollbar {
  display: none;
}

.closeModalContainer {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  margin-bottom: 1rem;
}
.closeModal {
  margin-right: 1rem;
  height: 1.3rem;
  cursor: pointer;
}

.content {
  position: relative;
  background-color: $bg-white;
  box-sizing: border-box;
  width: 100%;
  padding: 1.5rem;
  border-radius: .5rem;
  display: flex;
  flex-direction: column;
}

.descriptionContainer {
  margin-top: 1rem;
  background-color: $bg-lighter;
  padding: 1rem;
  border-radius: .5rem;
}
.message {
  font-weight: bold;
  margin-bottom: 1rem;
  font-size: 1rem;
  color: $text-grey;
}
.description {
  white-space: pre-wrap;
  color: $text-grey;
  font-size: .9rem;
  line-height: 1.5rem;
}

.actionContainer {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.actionText {
  text-align: center;
  color: $text-grey;
  font-size: .9rem;
  line-height: 1.5rem;
}
.buttonContainer {
  margin-top: 2rem;
  width: 100%;
  display: flex;
  flex: 1;
}
.coordinatesContainer {
  width: 100%;
  margin-top: 2rem;
}
.coordinatesTitle {
  font-size: 1.2rem;
  margin-left: 1rem;
  font-weight: bold;
  color: $text-black;
}
.coordinatesText {
  margin: .5rem 1rem 0 1rem;
  color: $text-black;
  font-size: .9rem;
}
.contactHeader {
  color: $burgundy;
  font-weight: bold;
  margin-bottom: 1rem;
  font-size: 1rem;
  margin-left: 1rem;
  margin-top: 2rem;
}
.contactButtons {
  margin-top: .5rem;
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
}
.spacer {
  width: .5rem;
}
