@import '../../../vars';

.container {
  position: relative;
  width: 100%;
  margin-bottom: 1rem;
}
.required {
  background-color: $burgundy;
  height: .7rem;
  width: .15rem;
  border-radius: .2rem;
  position: absolute;
  right: .7rem;
  top: 0;
  bottom: 0;
  margin: auto 0;
}
.error {
  position: absolute;
  top: .7rem;
  left: 1.6rem;
  color: $burgundy;
  font-size: .6rem;
  font-style: italic;
}

.selectContainer {
  width: 100%;
  box-sizing: border-box;
  background-color: $bg-lighter;
  border: none;
  border-radius: .3rem;
  outline: none;
  font-size: 1rem;
}
.selectControl {
  width: 100%;
  background-color: $bg-white;
  border: none;
  border-radius: .3rem;
  outline: none;
}
.selectControl:hover {
  outline: none;
}
.selectValueContainer {
  width: 100%;
  padding: 1.4rem 1.6rem;
}
.selectValueContainerFilled {
  padding: 0;
}
.selectIndicatorSeparator {
  width: 0;
}
.selectIndicatorsContainer {
  padding-right: 20px;
  color: rgba($text-grey, 0.8);
}
.selectPlaceholder {
  color: rgba($text-grey, 0.8);
  font-family: $lato;
  font-size: 1rem;
}
.selectMenu {
  margin-top: 5px;
  background-color: $bg-white;
  border-radius: .3rem;
  overflow: hidden;
  max-height: 25rem;
  box-shadow: 0 5px 5px 1px rgba($bg-grey, 0.6);
}
.selectOption {
  //padding: .8rem 1.5rem;
  color: $text-grey;
  font-family: $lato;
}
.selectOption:hover {
  background-color: $bg-lighter;
}

.othrLine {
  padding: .8rem;
  display: flex;
  flex-direction: row;
  align-items: center;
}
.othrName {
  margin-left: 1rem;
  color: $text-black;
}
.bold {
  font-weight: bold;
}
