@import '../../../../vars';

.container {
  background-color: $bg-lighter;
  width: 100%;
  padding: 3rem 4rem;
  box-sizing: border-box;
  position: relative;
}

.backButton {
  font-size: .6rem;
  color: $text-grey;
  position: absolute;
  top: 1.5rem;
  left: 4rem;
  font-weight: 600;
  cursor: pointer;
}

.underline {
  height: 2px;
  width: 50px;
  background-color: $bg-dark;
  margin-top: 4px;
  border-radius: 2px;
}

.ticketContainer {
  width: 100%;
  display: flex;
  flex-direction: row;
  margin-top: 1rem;
}
.ticketContainer:last-child {
  margin-bottom: 1.5rem;
}

.textContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  background-color: $bg-white;
  border-radius: 0.5rem;
  flex: 1;
  margin-right: 1rem;
  box-sizing: border-box;
  padding-left: 2rem;
  padding-right: 1rem;
  height: 5.5rem;
  //box-shadow: 0px 2px 5px 1px rgba(0,0,0,0.1);
}
.ticketName {
  font-weight: bold;
  font-size: 0.95rem;
  flex: 0.35;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  line-clamp: 3;
  -webkit-box-orient: vertical;
}
.ticketDescription {
  flex: 0.6;
  font-size: 1rem;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  line-clamp: 3;
  -webkit-box-orient: vertical;
  line-height: 1.2rem;
}

.priceContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: $bg-white;
  border-radius: 0.5rem;
  height: 5.5rem;
  width: 5.5rem;
  box-shadow: 0px 2px 5px 1px rgba(0,0,0,0.1);
}
.priceContainer:hover {
  cursor: pointer;
}
.price {
  color: $bg-dark;
  font-weight: 900;
  font-size: 1rem;
}
.priceSelected {
  color: $bg-white;
}
.ticketsSpan {
  color: $burgundy;
  font-size: .7rem;
  margin-top: .2rem;
  font-weight: bold;
}
.priceShown {
  color: $burgundy;
  font-size: 1.3rem;
  font-family: $lato;
}
.selected {
  background-color: $burgundy;
}

.buttonContainer {
  margin-top: 2rem;
  display: flex;
}

.form {
  min-height: 50px;
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-top: 2rem;
}
.formRow {
  display: flex;
  flex-direction: row;
}
.rowSpacer {
  width: 1rem;
}
.submitButton {
  margin-top: 2rem;
}

.addTicketButton {
  margin-left: 1.4rem;
  color: $text-grey;
  font-weight: bold;
  font-size: 0.8rem;
  cursor: pointer;
}

@media screen and (max-width: 800px) {
  .container {
    padding: 3rem 2.5rem;
  }
  .formRow {
    flex-direction: column;
    margin-bottom: 2rem;
  }
  .backButton {
    left: 2.5rem;
    top: 1.2rem;
    font-size: .8rem;
  }
}
