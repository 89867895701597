@import "../../vars";

.container {
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow-y: scroll;
  box-sizing: border-box;
}
.container > *:first-child {
  margin-top: 3rem;
}
.container > *:last-child {
  margin-bottom: 3rem;
}

.centeredWrapper {
  width: 100%;
  padding-left: 4rem;
  padding-right: 4rem;
  box-sizing: border-box;
}

.header {
  display: flex;
  width: 100%;
  flex-direction: row;
  align-items: center;
  padding: 2rem 4rem;
  box-sizing: border-box;
}
.eventIcon {
  height: 1.8rem;
}
.title {
  font-weight: bold;
  font-size: 1.2rem;
  margin-left: .8rem;
  color: $text-white;
}
.titleContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
}


.monthContainer {
  display: flex;
  width: 100%;
  flex-direction: column;
}

.filtersContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.searchContainer {
  position: absolute;
  right: 4rem;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.placeholder {
  min-height: 28rem;
}
