@import '../../../vars';

.centeredWrapper {
  padding-top: 3rem;
  padding-bottom: 5rem;
  width: 100%;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}
.centeredWrapper::-webkit-scrollbar {
  display: none;
}

.createParent {
  width: 50%;
  align-self: flex-end;
  margin-top: 1rem;
  opacity: .8;
}
.header {
  display: flex;
  width: 100%;
  padding-bottom: 2rem;
  box-sizing: border-box;
}

.form {
  min-height: 50px;
  width: 100%;
  display: flex;
  flex-direction: column;
}
.formRow {
  display: flex;
  flex-direction: row;
}
.rowSpacer {
  width: 1rem;
}
.submitButton {
  margin-top: 2rem;
}
.nameRow {
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 9rem;
}
.nameFields {
  flex: 1;
  display: flex;
  flex-direction: column;
  margin-bottom: -1rem;
}
.logo {
  display: flex;
  height: 100%;
  aspect-ratio: 1;
  margin-right: 1rem;
}
