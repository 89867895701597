@import '../../vars';

.container {
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow-y: scroll;
  box-sizing: border-box;
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
  padding: 3rem 0;
}
.container::-webkit-scrollbar {
  display: none;
}

.content {
  width: 40rem;
  display: flex;
  flex-direction: column;
}

.header {
  display: flex;
  flex-direction: row;
  width: 100%;
  align-items: center;
  padding: 2rem 1rem;
  box-sizing: border-box;
}
.form {
  min-height: 50px;
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-bottom: 2rem;
}
.deleteAccountButton {
  margin-top: 1rem;
  margin-bottom: 2rem;
}
