@import '../../../vars';

.header {
  display: flex;
  flex-direction: row;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  padding: 3rem 1rem 2rem 1rem;
  box-sizing: border-box;
}

.buttonContainer {
  //flex: 0.7;
}

.listHeader {
  box-sizing: border-box;
  min-height: 3rem;
  width: 100%;
  padding: 0 2rem;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
}
.ca {
  color: $text-white;
  font-weight: bold;
  font-size: .8rem;
}
.totalCa {
  color: $text-white;
  font-weight: bold;
  font-size: 1rem;
  //background-color: $green;
  //border-radius: .3rem;
  //padding: .4rem;
  //margin-left: .5rem;
}

.recommendationsList {
  position: relative;
  width: 100%;
  box-sizing: border-box;
  padding-bottom: 3rem;
  height: 100%;
  overflow-y: scroll;
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}
.recommendationsList::-webkit-scrollbar {
  display: none;
}

.shadow {
  position: sticky;
  top: 0;
  width: 100%;
  height: 1rem;
  background: linear-gradient(180deg, #00000066, #00000033, #00000000);
  pointer-events: none;
  box-sizing: border-box;
  z-index: 1;
}

.filtersContainer {
  margin-top: 1rem;
  width: calc(100% + 1.5rem);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 1rem;
  margin-left: -1.5rem;
  box-sizing: border-box;
}
.leftFilters {
  display: flex;
}
