.formRow {
  display: flex;
  flex-direction: row;
}
.rowSpacer {
  width: 1rem;
}

.ticketsContainer {
  margin-bottom: 2rem;
}
.ticketForm {
  margin-bottom: 2rem;
}
