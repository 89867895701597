@import '../../../vars';

.overlay {
  position: fixed;
  inset: 0px;
  background-color: rgba($bg-dark, 0.9);
  z-index: 35;
}

.modal {
  width: 20%;
  height: 20%;
  position: absolute;
  top: 40%;
  left: 40%;
  right: auto;
  bottom: auto;
  outline: none;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
