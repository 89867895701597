@import '../../../vars';

.header {
  width: 100%;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  align-items: center;
}
.infosContainer {
  display: flex;
  flex-direction: column;
  margin-left: 1rem;
}
.name {
  font-size: 1.3rem;
  font-weight: bold;
  margin-bottom: .1rem;
  color: $text-black;
}
.job {
  color: $text-black;
  font-size: .8rem;
  font-style: italic;
}
