@import '../../../vars';

.header {
  width: 100%;
  display: flex;
  align-items: center;
  background-color: $bg-white;
  padding: 2rem 4rem 1rem 4rem;
}
.headerInfos {
  display: flex;
  flex-direction: column;
  margin-left: 1rem;
}
.headerName {
  margin-bottom: .1rem;
  font-weight: bold;
  font-size: 1.4rem;
  color: $text-black;
}
.headerJob {
  font-size: 1rem;
  color: $text-black;
}

.content {
  padding: 0 4rem 2rem 4rem;
  background-color: $bg-white;
}

.contact {
  padding: 0 4rem 4rem 4rem;
}
.contactHeader {
  color: $burgundy;
  font-weight: bold;
  margin-bottom: 1rem;
  font-size: 1rem;
  margin-left: 1rem;
}
.contactButtons {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
}
.spacer {
  width: .5rem;
}
