@import '../../../vars';

.container {
  position: relative;
  box-sizing: border-box;
  border-radius: .3rem;
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
  min-height: 2.6rem;
  padding: 0 1rem;
  background: linear-gradient(80deg, #D20255, #D20255 10%, #F37D1D 65%, #FBBF02 95%, #FBBF02);
  opacity: .6;
  border: none;
  box-shadow: 0px 2px 5px 1px rgba(0,0,0,0.1);
  outline: none;
  pointer-events: none;
}
.container:hover {
  cursor: pointer;
  opacity: 0.9;
}
.enabled {
  opacity: 1;
  pointer-events: all;
}
.flexStart {
  justify-content: flex-start;
  padding-right: 1rem;
}

.text {
  color: $text-white;
  font-weight: bold;
  font-size: .9rem;
  line-height: 1rem;
  margin-block: 0;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  line-clamp: 1;
  -webkit-box-orient: vertical;
}
.icon {
  margin: 0 .5rem;
  height: 0.9em;
}

.legend {
  position: absolute;
  bottom: -1rem;
  color: $text-white;
  font-weight: bold;
  font-size: 0.7em;
}
