@import '../../../vars';

.container {
  margin-top: 1rem;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.text {
  color: $text-white;
  font-size: 1.2rem;
  font-style: italic;
}
.darkText {
  color: $text-black;
}
.smallText {
  font-size: .8rem;
}

.locationIcon {
  height: 0.7rem;
  margin-right: 0.4rem;
}
