@import '../../../vars';

.header {
  position: relative;
  width: 100%;
  aspect-ratio: 1.05;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-end;
  padding: 2rem 4rem;
  box-sizing: border-box;
}

.headerImage {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.shadow {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  background: linear-gradient(0deg, #000000ee, #00000033, #00000000);
}

.headerInfosContainer {
  z-index: 2;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  padding-right: .5rem;
}
.headerRoundButton {
  z-index: 2;
}

.surname {
  color: $text-white;
  font-family: $alexander-lettering;
  font-size: 7rem;
}
.headerTitle {
  color: $text-white;
  font-size: 3.4rem;
  line-height: 3.2rem;
  margin-top: .5rem;
  margin-bottom: .6rem;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  line-clamp: 2;
  -webkit-box-orient: vertical;
}
.subtitle {
  color: $text-white;
  font-size: 1.2rem;
}
.buttonContainer {
  width: 30%;
  display: flex;
}

.stats {
  width: 100%;
  height: 4.5rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: $bg-lighter;
  box-sizing: border-box;
  padding: 0 4rem;
}
.separator {
  height: 50%;
  width: 1.5px;
  background-color: $bg-grey;
  border-radius: 1px;
}

.content {
  padding: 2rem 4rem 0 4rem;
}
.contact {
  display: flex;
  flex: 1;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
  margin: 1rem 0 2rem 0;
}
.spacer {
  width: 4%;
}

.title {
  font-weight: bold;
  font-size: 1.2em;
  color: $bg-dark;
}

.commonContainer {
  margin-bottom: 2rem;
}
.commonSubtitle {
  color: $text-grey;
  font-style: italic;
  font-size: .8rem;
  margin-top: .5rem;
}
.commonLine {
  position: relative;
  left: -4rem;
  padding: 0 4rem;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  margin-top: 1rem;
  overflow-x: scroll;
  width: calc(100% + 8rem);
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}
.commonLine::-webkit-scrollbar {
  display: none;
}
.commonUser {
  margin-right: .6rem;
  display: flex;
  flex-direction: column;
}
.commonUserImage {
  height: 5rem;
  width: 5rem;
  margin-bottom: .5rem;
}
.commonUserName {
  font-size: .7rem;
  max-width: 4.5rem;
  height: 1rem;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}


.description {
  font-size: 0.8em;
  margin-top: 2rem;
  line-height: 2em;
  color: $bg-dark;
  margin-bottom: 2rem;
  white-space: pre-wrap;
}

.companyContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.companyInfosContainer {
  margin-left: 1rem;
  display: flex;
  flex-direction: column;
}
.company {
  font-weight: 800;
  font-size: .9rem;
  margin-bottom: .1rem;
}
.website {
  font-size: .7rem;
  font-style: italic;
  cursor: pointer;
  color: $text-grey;
}

.video {
  width: 100%;
  aspect-ratio: 2;
  margin-top: 3rem;
}
.carousel {
  width: 100%;
  box-sizing: border-box;
  margin-bottom: 2rem;
}
.mapSeparator {
  height: 1rem;
}

.footer {
  position: relative;
  width: 100%;
  aspect-ratio: 1;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-end;
  padding: 4rem;
  box-sizing: border-box;
  margin-top: 2rem;
}
.footerButton {
  flex: 1;
  margin-top: 1rem;
}
.footerInfosContainer {
  z-index: 2;
  width: 100%;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
}

.editButtonContainer {
  width: 100%;
  margin: 2rem 0;
  display: flex;
}

@media screen and (max-width: 800px) {
  .headerInfosContainer {
    padding: 0 2.5rem 2rem 2.5rem;
  }
  .content {
    padding: 2rem 2.5rem 2rem 2.5rem;
  }
}
