@import '../../../vars';

.centeredWrapper {
  padding-top: 3rem;
  padding-bottom: 5rem;
  width: 100%;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}
.centeredWrapper::-webkit-scrollbar {
  display: none;
}

.header {
  display: flex;
  width: 100%;
  padding-bottom: 2rem;
  box-sizing: border-box;
}

.form {
  min-height: 50px;
  width: 100%;
  display: flex;
  flex-direction: column;
}
.formRow {
  display: flex;
  flex-direction: row;
}
.rowSpacer {
  width: 1rem;
}
.submitButton {
  margin-top: 2rem;
}

.createAsContainer {
  padding-top: 4rem;
}
.spacer {
  height: 2rem;
}
.optionContainer {
  display: flex;
  flex-direction: row;
  background-color: $bg-white;
  width: 100%;
  padding: .5rem;
  border-radius: .5rem;
  margin-bottom: 1rem;
  cursor: pointer;
}
.optionInfosContainer {
  margin-left: .7rem;
  height: 4rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.optionName {
  color: $text-grey;
  font-weight: 500;
}
