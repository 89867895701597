@import '../../../vars';

.container {
  width: 100%;
  box-sizing: border-box;
  margin-top: 4rem;
  padding: 0 4rem;
}

input[type="range"] {
  width: 100%;
  --range-progress: 0;
  -webkit-appearance: none;
  appearance: none;
  background: transparent;
  cursor: pointer;
}
input[type="range"]::before {
  /* ... */
  background: #ff0;
  width: var(--range-progress);
  /* ... */
}
input[type="range"]::-webkit-slider-runnable-track {
  background: #242424;
  height: 0.1rem;
  border-radius: .5rem;
}

input[type="range"]::-moz-range-track {
  background: #242424;
  height: 0.1rem;
  border-radius: .5rem;
}
input[type="range"]::-webkit-slider-thumb {
  -webkit-appearance: none; /* Override default look */
  appearance: none;
  margin-top: -.3rem;
  background-color: #DD2944;
  height: .7rem;
  width: .7rem;
  border-radius: 100%;
}


.audio {
  display: none;
}
.controls {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}
.speed {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  position: relative;
  font-size: 1.2rem;
  cursor: pointer;
  width: 3rem;
}
.speedLegend {
  font-size: .5rem;
}
.centerControls {
  display: flex;
  align-items: center;
  justify-content: center;
}
.backTrackContainer {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
.backTrack {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  height: 2.3rem;
}
.backTrackText {
  margin-top: .1rem;
  font-size: .7rem;
  color: $text-black;
}
.volumeContainer {
  width: 4rem;
  position: relative;
  display: flex;
  align-items: center;
}
.volumeIcon {
  position: absolute;
  left: -1.4rem;
  width: 1.5rem;
}

.playButton {
  background-color: $burgundy;
  border-radius: 100%;
  padding: 1.2rem;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 4rem;
  width: 4rem;
  margin: 0 2rem;
}
.playIcon {
  height: 100%;
  cursor: pointer;
  margin-left: .3rem;
}
.pauseIcon {
  height: 100%;
  cursor: pointer;
}
.times {
  margin-top: .2rem;
  display: flex;
  width: 100%;
  justify-content: space-between;
}
.time {
  font-size: .7rem;
  color: $text-black;
}
