@import '../../../vars';

.error {
  position: absolute;
  top: .7rem;
  left: 1.6rem;
  color: $burgundy;
  font-size: .6rem;
  font-style: italic;
}
