@import '../../../vars';

.header {
  position: relative;
  width: 100%;
  height: 25rem;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-end;
  padding: 2rem 4rem;
  box-sizing: border-box;
}

.headerImage {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.shadow {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  background: linear-gradient(0deg, #000000ee, #00000055, #00000033);
}

.headerInfosContainer {
  z-index: 2;
  flex: 1;
  display: flex;
  flex-direction: column;
}

.squaresContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 10.5rem;
}
.square {
  height: 5rem;
  width: 5rem;
}

.headerTitle {
  color: $text-white;
  font-size: 2.6rem;
  margin-top: 1rem;
  margin-bottom: .2rem;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  line-clamp: 2;
  -webkit-box-orient: vertical;
}
.subtitle {
  color: $text-white;
  font-size: 0.9em;
}

.stats {
  width: 100%;
  height: 4rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: $bg-lighter;
  padding: 0 4rem;
  box-sizing: border-box;
}
.separator {
  height: 50%;
  width: 1.5px;
  background-color: $bg-grey;
  border-radius: 1px;
}

.content {
  padding: 2rem 4rem 0 4rem;
}

.title {
  font-weight: bold;
  font-size: 1.2em;
  color: $bg-dark;
}

.description {
  font-size: 1rem;
  margin-top: 2rem;
  line-height: 1.6rem;
  color: $text-black;
  margin-bottom: 2rem;
  white-space: pre-wrap;
}

.buttonsContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  font-size: 0.8rem;
}
.buttonContainer {
  flex: 0.48;
  display: flex;
}
.buttonSeparator {
  flex: 0.04;
}

.mapSeparator {
  height: 1rem;
}

.coordinatesContainer {
  margin-top: 2rem;
  padding: 2rem 4rem;
  background-color: $bg-lighter;
}
.coordinates {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 2rem;
}
.coordinatesInfos {
  display: flex;
  flex-direction: column;
  margin-left: .5rem;
  max-width: 80%;
}
.coordinatesName {
  font-weight: bold;
  margin-bottom: .2rem;
}
.coordinatesInfo {
  font-size: .8rem;
  color: $text-grey;
  line-height: 1rem;
  max-height: 1rem;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
.website {
  cursor: pointer;
}

.carousel {
  width: 100%;
  box-sizing: border-box;
  margin-bottom: 2rem;
}


.footer {
  position: relative;
  width: 100%;
  aspect-ratio: 1;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-end;
  padding: 4rem;
  box-sizing: border-box;
}
.footerButtons {
  flex: 1;
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-top: 1rem;
}

.footerButton {
  flex: 1;
  margin-top: .5rem;
}
