@import '../../../vars';

.container {
  border-radius: .3rem;
  border-width: 0.1rem;
  border-color: $text-white;
  border-style: solid;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 0.5rem;
  min-height: 2.6rem;
  box-sizing: border-box;
  outline: none;
}
.container:hover {
  cursor: pointer;
  opacity: 0.7;
}

.text {
  color: $text-white;
  font-weight: bold;
  font-size: .9rem;
  line-height: 1rem;
  margin-block: 0;
}
.icon {
  margin-right: .5rem;
  height: .8em;
}
