@import '../../../../vars';

.container {
  width: 100%;
  margin-top: 1.5rem;
  box-sizing: border-box;
  padding: 0 4rem;
}
.imageContainer {
  padding: 0;
}

.subtitle {
  margin-top: 2rem;
  font-weight: bold;
  color: $text-black;
  font-size: 1.6rem;
}
.paragraph {
  margin-top: 1rem;
  color: $text-black;
  line-height: 1.7rem;
  font-size: .9rem;
}
.image {
  margin-top: 2rem;
  width: 100%;
  object-fit: contain;
}
