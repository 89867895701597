@import '../../../vars';

.title {
  font-size: 1.1rem;
  color: $bg-dark;
}

.underline {
  height: 2px;
  width: 40px;
  background-color: $bg-dark;
  margin-top: 4px;
  border-radius: 2px;
}
