@import '../../../vars';

.container {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.icon {
  height: 1.7rem;
}
.title {
  font-size: 1.6rem;
  color: $text-white;
  margin-left: 1rem;
}
