@import '../../../vars';

.header {
  display: flex;
  flex-direction: row;
  width: 100%;
  align-items: center;
  padding: 2rem 2rem;
  box-sizing: border-box;
}
.title {
  color: $text-black;
  font-size: 1.3rem;
}

.form {
  width: 100%;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
}

.spacer {
  height: 1rem;
}
