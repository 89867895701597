@import '../../vars';

.header {
  display: flex;
  flex-direction: row;
  width: 100%;
  align-items: center;
  padding: 3rem 2rem 2rem 2rem;
  box-sizing: border-box;
}
.ticketsList {
  width: 100%;
  padding-bottom: 3rem;
  box-sizing: border-box;
  height: 100%;
  overflow-y: scroll;
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}
.ticketsList::-webkit-scrollbar {
  display: none;
}

.filtersContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 1rem;
  width: calc(100% + 1.5rem);
  margin-left: -1.5rem;
  box-sizing: border-box;
}
