@import '../../../vars';

.container {
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  color: $text-black;
  cursor: pointer;
}

.infosContainer {
  cursor: pointer;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  box-sizing: border-box;
  margin-left: 1rem;
  overflow: hidden;
  margin-right: 2rem;
  max-width: 60%;
}
.icon {
  height: 1.5rem;
  margin-left: .5rem;
  margin-bottom: -.1rem;
  background-color: $bg-white;
  padding: 1.25rem;
  box-shadow: 0 2px 5px 1px rgba(0,0,0,0.1);
  border-radius: .5rem;
}
.name {
  font-weight: bold;
  font-size: 1rem;
  height: 1rem;
  line-height: 1rem;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.subtitle {
  font-size: .9rem;
  margin-top: .2rem;
}
.arrowRight {
  position: absolute;
  right: .5rem;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  height: .8rem;
}

.date {
  position: absolute;
  right: .5rem;
  bottom: -.2rem;
  font-style: italic;
  font-size: .6rem;
  color: $text-black;
}
