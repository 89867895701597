@import '../../../vars';

.centeredWrapper {
  padding-top: 3rem;
  padding-bottom: 5rem;
  width: 100%;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
}

.closeModalContainer {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  margin-bottom: 1rem;
}
.closeModal {
  margin-right: 1rem;
  height: 1.3rem;
  cursor: pointer;
}

.content {
  position: relative;
  //background-color: $text-white;
  box-sizing: border-box;
  width: 100%;
}

.header {
  width: 100%;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  align-items: center;
}
.headerTitle {
  color: $text-black;
}


.form {
  width: 100%;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  margin-top: 2rem;
}

.formHeader {
  display: flex;
  align-items: center;
  margin-bottom: 1.5rem;
}
._formHeaderButton {
  width: 7rem;
  height: 7rem;
  margin-right: .5rem;
}
.formHeaderLogo {
  width: 5rem;
  height: 5rem;
}
.formHeaderInfos {
  margin-left: .5rem;
}
.formHeaderTitle {
  font-size: 1.3rem;
  color: $text-white;
  font-weight: 600;
}
.formHeaderGroup {
  font-size: 0.8rem;
  margin-top: .2rem;
  color: $text-white;
}

.formTitle {
  font-weight: bold;
  font-size: 1rem;
  margin-left: 1.4rem;
  margin-bottom: 1rem;
}
.formFooter {
  margin: 0 1.4rem 1rem 1.4rem;
  font-style: italic;
  font-size: .7rem;
  color: $text-grey;
}
