.container {
  position: relative;
  height: 100vh;
  width: 100%;
  box-sizing: border-box;
  background-color: #ffffff;
}
.background {
  position: absolute;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100%;
  opacity: .9;
  object-fit: cover;
}
