@import '../../../vars';

.container {
  position: relative;
  border-radius: .3rem;
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
  min-height: 2.6rem;
  background-color: $bg-white;
  box-shadow: 0 2px 5px 1px rgba(0,0,0,0.1);
  border: none;
  outline: none;
  padding: 0 1.2rem;
  cursor: pointer;
}
.container:hover {
  opacity: 0.7;
}
.disabled {
  box-shadow: none;
  background-color: rgba($bg-lighter, .5);
  pointer-events: none;
  .text {
    opacity: .5;
  }
}
.flexStart {
  justify-content: flex-start;
  padding-right: 1rem;
}

.text {
  color: $text-grey;
  font-weight: bold;
  font-size: .9rem;
  line-height: 1rem;
  height: 1rem;
  margin-block: 0;
  overflow: hidden;
  text-align: left;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.icon {
  margin-right: .5rem;
  height: 50%;
}

.legend {
  position: absolute;
  bottom: -1.2rem;
  color: $text-grey;
  font-weight: bold;
  font-size: 0.7em;
  line-height: 1rem;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  line-clamp: 1;
  -webkit-box-orient: vertical;
  margin-top: .5rem;
}
.greenLegend {
  color: $green;
}
.redLegend {
  color: $burgundy;
}

.rightAction {
  position: absolute;
  top: -.5rem;
  right: -.5rem;
  width: 1.5rem;
  height: 1.5rem;
  border-radius: 2rem;
  background-color: $bg-white;
  box-shadow: 0 1px 3px .5px rgba(0,0,0,0.05);
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}
.rightActionIcon {
  margin-top: .1rem;
  margin-left: .05rem;
  height: .8rem;
}
